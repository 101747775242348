* {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Libre Baskerville", serif;
}

.fixed-top {
  z-index: 999
}

.loading {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 50%;
  top: 50%;
}


@media only screen and (max-width: 992px) {
  .smallNav {
    background-color: black !important;
  }

  .navImage {
    position: relative;
    left: -30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .mainBig {
    padding-top: 12vmax;
  }
}

.cDiv {
  /* height: min-content; */
  padding-top: 17vh;
}

.mdImage {
  position: relative;
  height: 100%;
}

.centricoSmall {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 24vh;
  left: 0;
  font-size: 5vmax;
  color: white;
  text-align: center;
}

.mdImage {
  /* width: 100%;
    padding-top: 10vmax; */
}

.mainBig {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)),
    url(./images/background/main.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: rgba(255, 255, 255, 1);
  padding-bottom: 15vmax;
}

.mainh1 {
  padding: 0.5vmax;
  font-size: 3.7vmax;
}

.fupper {
  text-transform: capitalize;
  color: rgba(189, 189, 189, 1);
  font-family: "Open Sans", sans-serif;
  text-shadow: 2px 2px #000;
  font-size: 1.15vmax;
}

.mainIcons {
  position: fixed;
  z-index: 3;
  top: 38%;
  right: 0.5rem;
}

.mainIcons>a {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;
  margin: 0.5vmax auto;
  border: 1px solid white;
  box-shadow: inset 0px 0px 15px #000;
  border-radius: 100%;
}

.mainIcons>a svg {
  font-size: 20px;
}

.mainIcons>a:hover {
  color: rgba(255, 252, 252, 0.619);
}

.fa-whatsapp,
.fa-facebook-messenger {
  color: white;
}

.animation {
  display: flex;
  align-items: center;
  padding: 4vmax 0 0 0;
}

.animation>p {
  color: rgba(203, 191, 167, 1);
  padding-left: 1.5vmax;
}

.mm {
  padding-top: 2vmax;
}

.last {
  margin: 0;
  padding: 1.5vmax 0 0 1vmax;
  color: rgba(203, 191, 167, 1);
}

.mouse {
  margin-top: 2vmax;
  width: 2vmax;
  height: 4vmax;
  border: 3px solid rgba(203, 191, 167, 1);
  border-radius: 60px;
  position: relative;
}

.mouse::before {
  content: "";
  width: 0.6vmax;
  height: 0.6vmax;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(203, 191, 167, 1);
  border-radius: 50%;
  opacity: 1;
  animation: wheel 1.5s infinite;
  -webkit-animation: wheel 1.5s infinite;
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 2.5vmax;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 2.5vmax;
  }
}

.leftCol {
  padding: 10vmax 0vmax 5vmax 5vmax;
}

.leftCol>img {
  padding-bottom: 1vmax;
}

.fupper {
  padding: 1vmax 1vmax 0.5vmax 0;
}

.rightCol {
  background: rgba(242, 242, 242, 0.2);
  margin: 6vmax 2vmax 2vmax 0;
  width: 50%;
}

.galleryImage {
  width: 100%;
  padding: 0 0.5vmax 0 0.5vmax;
}

.rightColExpand {
  background: rgba(242, 242, 242, 0.2);
  margin: 6vmax 2vmax 2vmax 0;
  padding-bottom: 7vmax;
  position: absolute;
  width: 95%;
  right: 0;
}

.galleryImage {
  padding: 0 0.5vmax 0 0.5vmax;
}

.slick-slider {
  padding-top: 9vmax;
}

.slick-slide {
  max-width: max-content;
}

.underSliderText {
  padding: 2vmax 0 3vmax 0;
}

/* *********************** ABOUT *********************** */
.about {
  background-color: black;
  padding-bottom: 15vmax;
}

.aboutRow {
  padding: 4vmax 0 12vmax 0;
}

.aboutImage {
  background: linear-gradient(rgba(0, 0, 0, 0.221), rgba(0, 0, 0, 0)),
    url(./images/background/aboutOrange.png);
  background-position: left;
  background-repeat: no-repeat;
  background-size: 300px;
  /* background-size: contain; */
  /* height: 100vh; */
}

.aboutText {
  color: rgba(255, 255, 255, 1);
  padding: 2vmax;
}


.aboutParagraph {
  color: rgba(189, 189, 189, 1);
  font-family: "Open Sans", sans-serif;
  font-size: 1.15vmax;
  padding-top: 2vmax;
}

@media only screen and (max-width: 992px) {
  .aboutParagraph {
    max-width: 75%;
    margin-left: auto;
  }
}

.bites,
.cocktails {
  margin: 3vmax 0;
}

.text {
  background-color: rgba(227, 224, 218, 1);
  margin: 2vmax;
  padding: 2vmax;
  position: relative;
}

.bitesImage>img,
.cocktailsImage>img {
  position: absolute;
  top: -75px;
  right: -5px;
  max-width: 100%;
  max-height: 50%;
}

@media only screen and (max-width: 860px) {

  .bitesImage>img,
  .cocktailsImage>img {
    position: absolute;
    top: -65px;
    right: -5px;
    max-width: 100%;
    max-height: 50%;
  }
}

@media only screen and (max-width: 445px) {

  .bitesImage>img,
  .cocktailsImage>img {
    position: absolute;
    top: -85px;
    right: -5px;
    max-width: 100%;
    max-height: 40%;
  }
}

.text>h5 {
  font-size: 2vmax;
  margin: 2vmax 0 2vmax 0;
}

.text>p {
  font-family: "Open Sans", sans-serif;
  font-size: 1.15vmax;
}

.bites>p,
.cocktails>p {
  color: rgba(57, 57, 57, 1);
}

/* *********************** MENU *********************** */

.menu {
  padding-bottom: 9vmax;
  background-color: black;
  font-family: "Libre Baskerville", serif;
  position: relative;
  /* background-image:url(./images/background/menuGin.png);
  background-repeat: no-repeat;
  background-position : right 20%;
  background-size : 300px; */
}

.menuItem {
  z-index: 1;
  padding: 5vmax 0 0 5vmax;
  min-height: 75vh;
}

.menuImageWine {
  background: url(./images/background/menuWine.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
}

.menu-row {
  margin: 20px 0;
}

.menu-row img {
  aspect-ratio: 1/1;
}

.menuGin {
  position: absolute;
  right: 0;
  bottom: 20%;
  width: 50%;
}


.ourMenus {
  color: rgba(255, 255, 255, 1);
  padding-bottom: 3vmax;
  padding-left: 5vmax;
}

.menuButtonHandler {
  z-index: 1;
  display: flex;
  justify-content: center;
}

.menuCategoryContainer,
.menuItemsContainer {
  padding: 0 5vmax 0;
}

.menuButtonHandler>button:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 1)
}

.menuButtons {
  background: transparent;
  flex: 1;
  border: none;
  color: rgba(242, 242, 242, 1);
  margin: 0.5rem;
  height: 3rem;
  margin: 0;
  padding: 1vmax 0 1vmax 0;
}

.menuButtons.active,
.menuButtons:hover {
  color: yellow;
}


@media only screen and (max-width: 992px) {

  .menuGin {
    display: none;
  }

  .menuItem {
    min-height: 300px;
    padding: 0 3.8vmax 0
  }

  .menuButtonHandler {
    flex-wrap: wrap;
  }

  .menuButtonHandler>button:not(:last-child) {
    border-right: 0px;
  }

  .menuButtons {
    background: linear-gradient(99.09deg,
        #9e2b2e 0%,
        #852d3c 48.96%,
        #8c272e 100%);
    border-right: 0px;
    margin: 5px;
    flex: 0 0 29%;
    max-width: 29%;
  }

  .menuButtons.active {
    background: linear-gradient(99.09deg, #9e2b2e 0%, #852d3c 48.96%, #8c272e 100%);
  }
}

@media only screen and (max-width: 768px) {

  .menuCategoryContainer,
  .menuItemsContainer {
    padding: 0 2vmax 0;
  }
}

.eachItem {
  padding: 0.25vmax 0.5vmax;
}

.foodName,
.foodDescription {
  color: rgba(242, 242, 242, 1);
}

.foodName {
  font-size: 1.5vmax;
}

.foodDescription {
  font-size: 0.75vmax;
}

.foodPrice {
  color: rgba(1, 132, 166, 1);
  font-size: 1.2968vmax;
}

/* *********************** GALLERY *********************** */

.gallery {
  /* height: 100vh; */
  background-color: black;
  padding-bottom: 15vmax;
}

.galleryh3 {
  font-size: 3.14vmax !important;
  color: rgba(255, 255, 255, 1);
  padding-bottom: 3vmax;
}

.ourGalleryImage {
  width: 100%;
  /* padding: 1vmax; */
}

.ourGalleryRow>.slick-slider {
  padding: 0;

  .slick-slide {
    height: 320px;

    >div {
      height: 100%;

      .galleryImages {
        height: 100%;

        img {
          height: 100%;
        }
      }
    }
  }
}

.slick-next:before,
.slick-prev:before {
  color: rgb(29, 128, 153);
  /* border-radius: 32px; */
}

.slick-dots li.slick-active button:before {
  color: white;
}

.slick-dots li button:before {
  color: #6fcdaa;
}

/* *********************** CONTACT *********************** */

.contact {
  background-color: black;
  padding-bottom: 8vmax;
}

.contact>h5 {
  color: rgba(227, 224, 218, 1);
  padding-bottom: 1vmax;
}

.contact>h1 {
  color: rgba(255, 255, 255, 1);
}

.contactContainer {
  padding-top: 3vmax;
}

@media only screen and (min-width: 992px) {
  .contactTextWrap {
    padding-left: 5vmax;
  }
}

.contactTextWrap>* {
  color: rgba(251, 251, 241, 1);
}

.contactSpacing {
  padding-bottom: 2vmax;
}

/* *********************** FOOTER *********************** */

.footer {
  background-color: black;
  color: rgba(142, 142, 147, 1);
}

.footer-top,
.titleRow>h3 {
  color: rgba(255, 255, 255, 1);
}

.titleRow a,
.inTouch a {
  margin-right: 1rem;
}

.copyright {
  padding-top: 4vmax;
}

.inTouch>h2 {
  color: rgba(255, 255, 255, 1);
}

.inTouch>p {
  color: rgba(142, 142, 147, 1);
  font-size: 1.75vmax;
}

.spacing {
  position: relative;
  padding-bottom: 4vmax;
}

.spacing::before {
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  bottom: 0;
  left: calc(5%);
  background-color: white;
}

.venues {
  position: relative;
  padding-bottom: 4vmax;
}

.venues::before {
  position: absolute;
  content: "";
  width: 90%;
  height: 1px;
  bottom: 0;
  left: calc(5%);
  background-color: white;
}

.venueList {
  color: rgba(142, 142, 147, 1);
  font-size: 1.75vmax;
}

.venue {
  color: rgba(255, 255, 255, 1);
  padding-top: 4vmax;
}

.lastFooter {
  padding-top: 4vmax;
}

.lastFooter>p {
  color: rgba(142, 142, 147, 1);
  font-size: 1.75vmax;
}

.smallIgIcon {
  margin-left: 1vmax;
}

a {
  text-decoration: none;
  color: rgba(142, 142, 147, 1);
}

a:hover {
  text-decoration: none;
  color: rgb(86, 86, 89);
}



.navbar-brand {
  margin-right: 0rem;
}

.navbar-nav {
  width: 100%;
  max-width: 1000px;
}

.navbar-nav .nav-link {
  flex: 1;
  text-align: center;
}



/* fix */
@media screen and (max-width:768px) {

  .fupper,
  .aboutParagraph,
  .text>p {
    font-size: 1.5vmax;
  }

}

body .ril-caption {
  justify-content: center;
}